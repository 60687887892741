import React, { PureComponent } from 'react'

import Layout from '../components/layout'
import Wrapper from '../components/Wrapper'
import SEO from '../components/SEO'
import barricade from '../../content/images/barricade.svg'
import logo from '../../content/images/Omninox_logo.png'

export default class HomePage extends PureComponent {
  render() {
    return (
      <Layout limbless>
        <SEO />
        <Wrapper>
          <img src={logo} alt="Omninox logo" style={{ width: '45%', height: 'auto' }} />
          <img src={barricade} alt="Barricade icon" style={{ width: '60%', height: 'auto' }} />
          <p style={{ fontSize: 10 }}>
            Icon made by <a href="https://www.flaticon.com/authors/ultimatearm" title="ultimatearm">ultimatearm</a> from <a href="https://www.flaticon.com/" title="Flaticon">Flaticon</a>
          </p>
          <br />
          <br />
          <h2 style={{ fontWeight: 'lighter' }}>Under Renovation</h2>
          <br />
          <h4 style={{ color: '#008282' }}>Until Spring 2023</h4>
          <br />
          <br />
          <section style={{ textAlign: 'justify' }}>
            <strong>Summer 2020</strong>
            <br />
            <br />
            <p>The Omninox Assessment platform has reached the end of its support life cycle. Although it was ahead of its time, the College Board and other organizations have since stepped in to close the assessment gap in AP classes. Due to the drastic changes that are coming to education in the fall, Omninox will focus on creating open assessment content that is built for purely virtual and hybrid classrooms.</p>
            <br />
            <p>To stay updated on these efforts, visit our official publishing <a href="https://blog.omninox.org">blog on education and technology.</a></p>
          </section>
        </Wrapper>
      </Layout>
    )
  }
}